import React from 'react'
import styled from "@emotion/styled";
import {mobileBreakpoint} from "../../constants";

interface Props {

}

const Button = styled.button`
  font-size: ${({theme}) => theme.fontSize.main}px;
  font-family: ${({theme}) => theme.fontFamily};
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  line-height: 1;
  user-select: none;
  color: black;
  @media(max-width: ${mobileBreakpoint}px){
    font-size: ${({theme}) => theme.fontSizeMobile.main}px;
  }
`
export default Button;
