import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {ThemeProvider} from '@emotion/react';
import theme from "../style/theme";
import GlobalStyleInject from "../style/GlobalStyleInject";
import PageFrame from "../components/Layout/PageFrame";
import {useShopify} from "../utils";
import {ShopifyContext} from '../context';
import {useShopInfo} from "../hooks/use-shop-info";
import {useSiteMetadata} from "../hooks/use-site-metadata";

interface Props {
  children: ReactNode
}

declare global {
  interface Window {
    ShopifyBuy?: any;
  }
}


const Layout: React.FC<Props> = ({children}) => {
  const {title} = useSiteMetadata();
  const cartContainer = useRef<HTMLDivElement>(null)
  const buyButton = useShopify();
  const [shopifyReady, setShopifyReady] = useState(false);
  const shopifyClientRef = useRef(null);
  const shopifyCartRef = useRef(null);
  const {money_format, currency} = useShopInfo();

  // Shopify UI initialization
  useEffect(() => {
    if (buyButton === "error") {
      console.error("Failed to load buy button integration, this is a problem!")
    } else if (buyButton === "ready" && !shopifyClientRef.current) {
      shopifyClientRef.current = window.ShopifyBuy.buildClient({
        domain: process.env.GATSBY_SHOPIFY_STORE_URL,
        storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN
      })
      const ui = window.ShopifyBuy.UI.init(shopifyClientRef.current)
      ui.createComponent('cart', {
        node: cartContainer.current,
        moneyFormat: money_format, options: {
          cart: {
            text: {
              currency
            }
          }
        },
      }).then((cart: any) => {
        console.log("Cart created!")
        shopifyCartRef.current = cart;
        setShopifyReady(true);
      })
    } else {
      console.log(`Buy button status: ${buyButton}`)
    }
  }, [buyButton])

  return (
    <ThemeProvider theme={theme}>
      <ShopifyContext.Provider value={{shopifyReady, shopifyCartRef, shopifyClientRef}}>
        <GlobalStyleInject/>
        <div ref={cartContainer}></div>
        <PageFrame>{children}</PageFrame>
      </ShopifyContext.Provider>
    </ThemeProvider>
  )
}

export default Layout
