import "@emotion/react";
import {shadowColor} from "../constants";

export default {
  fontFamily: "G2-Kosmos, sans-serif",
  fontSize : {
    main: 20,
    big: 40,
  },
  fontSizeMobile : {
    main: 16,
    big: 30,
  },
  margin: {
    small: 2.5,
    main: 5,
    big: 20,
  },
  color: {
    primary: "#000000",
    secondary: "#2B2E34",
    tertiary: "#EAEAEA",
    quaternary: "#707070",
    shadow: shadowColor,
    info: "#0014FF",
    success: "#2BB900",
    danger: "#CC0000"
  },
  boxShadow: `inset 0px 3px 56px ${shadowColor};`
}
