import React, {useContext} from 'react'
import {Link} from "gatsby";
import BaseBar from "./BaseBar";
import {ShopifyContext} from "../../context";
import Button from "../Button/Button";
import {css} from "@emotion/react";


const Navbar = () => {
  const {shopifyReady, shopifyCartRef, shopifyClientRef} = useContext(ShopifyContext)
  return (
    <BaseBar>
      <Link to="/info">Info</Link>
      <Link to="/">www.typeoclock.com</Link>
      <Button
        css={css`
          opacity: ${shopifyReady ? 1 : 0};
          transition: all .5s ease;
        `}
        disabled={!shopifyReady}
        onClick={() => {
          setTimeout(() => {
            shopifyCartRef?.current.open();
          }, 100)
        }}
      >
        Cart
      </Button>
    </BaseBar>
  )
}

export default Navbar;
