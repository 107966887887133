import {graphql, useStaticQuery} from "gatsby"

interface ShopInfo {
  currency: string,
  customer_email: string,
  iana_timezone: string,
  money_format: string,
  money_with_currency_format: string,
  name: string
}

export const useShopInfo: () => ShopInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      shopifyShop {
        currency
        customer_email
        iana_timezone
        money_format
        money_with_currency_format
        name
      }
    }
  `)

  return data.shopifyShop
}
