import React, {MutableRefObject} from "react";

interface ShopifyContextData {
  shopifyReady: boolean,
  shopifyClientRef?: MutableRefObject<any>,
  shopifyCartRef?: MutableRefObject<any>
}

export const ShopifyContext = React.createContext<ShopifyContextData>({
  shopifyReady: false,
  shopifyClientRef: undefined,
  shopifyCartRef: undefined
})
