import React from 'react'
import {css, Global, useTheme} from "@emotion/react";
import emotionReset from "emotion-reset";
import kosmosSrc from "../assets/G2Kosmos-Regular.otf";
import {mobileBreakpoint} from "../constants";

const GlobalStyleInject = () => {
  const theme = useTheme();
  const globalStyles = css`
    @font-face {
      font-family: G2-Kosmos;
      src: url("${kosmosSrc}");
    }

    * {
      box-sizing: border-box;
      text-transform: uppercase;
      &::selection {
        background: ${theme.color.tertiary};
      }
    }

    html {
      font-family: ${theme.fontFamily};
      font-size: ${theme.fontSize.main}px;
      @media (max-width: ${mobileBreakpoint}px) {
        font-size: ${theme.fontSizeMobile.main}px;
      }
    }

    body {
      overflow: hidden;
    }

    main {
      flex-grow: 2;
      overflow: hidden;
    }

    a {
      color: ${theme.color.primary};
      text-decoration: none;

      transition: .2s ease;

      &:hover {
        color: ${theme.color.secondary};
      }
    }

    button {
      font-size: ${theme.fontSize.main}px;
      @media (max-width: ${mobileBreakpoint}px) {
        font-size: ${theme.fontSizeMobile.main}px;
      }
    }

    .shopify-buy-frame--toggle {
      display: none !important;
    }
  `
  return (
    <Global styles={css`
      ${emotionReset}
      ${globalStyles}
    `}/>
  )
}

export default GlobalStyleInject;
