import {Theme} from "@emotion/react";
import {DisplayProduct, MetaField, ShopifyProductNode, ShopifyProductSingle} from "./types/data";
import {IGatsbyImageData} from "gatsby-plugin-image";
import {useEffect, useState} from "react";
import {buyButtonURL} from "./constants";

export const getConstrained = (val: number, constraint: number) => {
  const constrainedVal = val % constraint;
  if (constrainedVal < 0) {
    return (constraint - Math.abs(constrainedVal))
  }
  return constrainedVal
}

// map function from processing / p5.js
export const reMap = (n: number, start1: number = 0, stop1: number = 0, start2: number = 0, stop2: number = 0) =>
  (n - start1) / (stop1 - start1) * (stop2 - start2) + start2;

// populate a list to a minimum length by looping existing items
export const populateMin = (list: any[], minLength: number) => {
  if (list.length === 0 || list.length >= minLength) return list;
  const loopCount = Math.ceil(minLength / list.length);
  const newList = [];
  for (let i = 0; i < loopCount; i++) {
    newList.push(...list)
  }
  return newList
}

export const spreadVariants = (productNodes: ShopifyProductNode[]) => {
  const displayProducts : DisplayProduct[] = [];
  productNodes.forEach(node => {
    node.variants.forEach(variant => {
      displayProducts.push({
        id: node.id,
        title: node.title,
        vendor: node.vendor,
        handle: `${node.handle}${node.variants.length > 1 ? `?variant=${getVariantHandle(variant.title)}` : ''}`,
        imageData: variant.image?.gatsbyImageData
      })
    })
  })
  return displayProducts;
}

export const getVariantHandle = (variantTitle: string) => encodeURIComponent(variantTitle.toLowerCase());

/**
 * Map value using this cubic function:
 * f(x)=(x-1)^(3)+x-1+2
 *
 * For use in distributing clocks on the circular carousel
 *
 * @param value
 */
export const getSmoothCircleMapped = (value: number) => {
  const a = value * 2
  const cubicValue = (Math.pow(a - 1, 3) + (a - 1)) + 2
  return reMap(cubicValue, 0, 4, 0, 1)
}

/**
 * Map value using this function:
 * g(x)=(x-1)^(3)+1
 *
 * For use in distributing clocks on the mobile depth carousel
 *
 * @param value
 */
export const getSmoothLineMapped = (value: number) => Math.pow((value - 1), 3) + 1

export const roundToNearest = (value: number, interval: number) => {
  return Math.round(value / interval) * interval;
}

export const topBarHeight = (theme: Theme) => theme.fontSize.main + (theme.margin.main * 2);

export const getWindowHeight = () => {
  if (typeof window === `undefined`) {
    return 1080
  }
  return window.innerHeight
}

export const getWindowWidth = () => {
  if (typeof window === `undefined`) {
    return 1920
  }
  return window.innerWidth
}

export const mapMetaFields = (fields: MetaField[]) => {
  const fieldMap: { [key: string]: string } = {}
  for (const field of fields) {
    fieldMap[field.key] = field.value
  }
  return fieldMap
}

export const parseProductData = (data: ShopifyProductSingle) => {
  const variantImageIDs: string[] = []
  data.variants.forEach(v => {
    variantImageIDs.push(...v.media.map(m => m.shopifyId))
  })
  const images = data.media
    .filter(i => !variantImageIDs.includes(i.shopifyId))
    .map(i => ({
      data: i.preview.image.gatsbyImageData,
      alt: i.alt,
      id: i.shopifyId
    }));
  return {
    product: data,
    images,
    fields: mapMetaFields(data.metafields)
  }
}

export const displayURL = (url: string) => url
  .replace('https://', '')
  .replace('http://', '')

// only the 2D vector functions I need :)
export class MiniVec {
  x: number
  y: number

  constructor(x: number, y: number) {
    this.x = x || 0;
    this.y = y || 0;
  }

  subtract(v: MiniVec) {
    return new MiniVec(this.x - v.x, this.y - v.y);
  }

  dot(v: MiniVec) {
    return this.x * v.x + this.y * v.y
  }

  length() {
    return Math.sqrt(this.dot(this));
  }

  divide(n: number) {
    return new MiniVec(this.x / n, this.y / n);
  }

  unit() {
    return this.divide(this.length());
  }
}

// Hook to load script from cdn
export type Status = 'loading' | 'ready' | 'error'

export type ScriptElt = HTMLScriptElement | null

export function useShopify(): Status {
  const [status, setStatus] = useState<Status>('loading')
  useEffect(
    () => {

      // Check if lib already initialized
      if (window.ShopifyBuy) {
        setStatus("ready" as Status)
        return;
      }

      // Fetch existing script element by src
      // It may have been added by another instance of this hook
      let script: ScriptElt = document.querySelector(`script[src="${buyButtonURL}"]`)
      if (!script) {
        // Create script
        script = document.createElement('script')
        script.src = buyButtonURL
        script.async = true
        script.setAttribute('data-status', 'loading')
        // Add script to document body
        document.body.appendChild(script)

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: Event) => {
          script?.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error',
          )
        }
        script.addEventListener('load', setAttributeFromEvent)
        script.addEventListener('error', setAttributeFromEvent)

      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status') as Status)

      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add

      // event handlers to update the state for *this* hook instance.

      const setStateFromEvent = (event: Event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error')
      }

      // Add event listeners
      script.addEventListener('load', setStateFromEvent)
      script.addEventListener('error', setStateFromEvent)

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent)
          script.removeEventListener('error', setStateFromEvent)
        }
      }
    },
    [], // Only re-run effect if script src changes
  )
  return status
}

