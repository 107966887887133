import {css} from "@emotion/react";

export const shadowColor = "#00000029"
export const hideScrollbar = css`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
export const buyButtonURL = `https://sdks.shopifycdn.com/buy-button/2.4.0/buybutton.js`;
export const mobileBreakpoint = 750
