import React, {ReactNode} from 'react'
import Navbar from "../Navbar/Navbar";
import {css} from "@emotion/react";
import {useWindowSize} from "rooks";

interface Props {
  children: ReactNode
}

const PageFrame: React.FC<Props> = ({children}) => {
  const {innerHeight} = useWindowSize();

  return (
    <div css={css`
      width: 100vw;
      height: ${innerHeight ? `${innerHeight}px` : '100vh'};
      display: flex;
      flex-direction: column;
      overflow: hidden;
    `}>
      <div css={css`
        flex-shrink: 0;
        flex-grow: 0;
      `}>
        <Navbar/>
      </div>
      {children}
    </div>
  )
}

export default PageFrame;
