import React, {ReactNode} from 'react'
import {css, useTheme} from "@emotion/react";
import {Link} from "gatsby";

interface Props {
  children: ReactNode,
  background?: string
}

const BaseBar: React.FC<Props> = ({children, background = "white"}) => {
  const theme = useTheme();
  return (
    <div css={css`
      user-select: none;
      display: flex;
      text-transform: uppercase;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: ${theme.margin.main}px;
      background: ${background};
    `}>
      {children}
    </div>
  )
}

export default BaseBar;
